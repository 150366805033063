import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HttpService} from '../../../services/http.service';
import {HttpErrorsService} from '../../../services/http-errors.service';

export class AtpActionsPopupData {
  constructor(public title: string, public message: string | string[], public successBtnTitle: string = 'Ок', public cancelBtnTitle: string = 'Отменить') { }
  successBtnColor = 'primary';
}

export class AtpActionsPopupComponent<T extends AtpActionsPopupComponent<T>> {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AtpActionsPopupData,
    protected dialogRef: MatDialogRef<T>
  ) {
    if (typeof (data.message) == 'string') {
      this.message = data.message;
    }
    else if (Array.isArray(data.message) && typeof (data.message[0]) != 'object' ) {
      this.messages = data.message;
    }
    else if (typeof (data.message) == 'object'){
      this.files = data.message;
    }
  }

  message: string;
  messages: string[];
  files: any[];

  close(result: boolean) {
    this.dialogRef.close(result);
  }
}

@Component({
  selector: 'atp-actions-popup',
  templateUrl: './atp-actions-popup.component.html',
  host: {
    class: 'atp-actions-popup'
  }
})
export class AtpActionsPopup extends AtpActionsPopupComponent<AtpActionsPopup> {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: AtpActionsPopupData,
    dialogRef: MatDialogRef<AtpActionsPopup>,
    private _api: HttpService,
    private _httpErrors: HttpErrorsService
  ) {
    super(data, dialogRef);
  }

  openFile(id: string) {
    this._api.getFile(id, false).subscribe(
      (data) => {
      },
      err => {
        this._httpErrors.process(401, () => { this.openFile(id); }, null);
      }
    );
  }
}
